<ng-container *ngIf="realSettings" [ngSwitch]="realSettings.type">
    <div *ngSwitchCase="'EDIT_WITH_TITLE'" (click)="emitNewEvent(realSettings.byKey || 'EDIT', row)">
        <span>{{'table.tool_title.edit' | translate}}</span>
    </div>


    <img *ngSwitchCase="'DELETE_FOREVER'"
         src="assets/images/icons/delete_forever.png"
         class="delete_forever"
         (click)="emitNewEvent(realSettings.byKey || 'DELETE_FOREVER', row)">

    <div class="tooltip mt-10" *ngSwitchCase="'COLORS'">
        <div class="tooltiptext">{{row['rgb']}}</div>
        <div *ngIf="row['rgb'] == 'MULTIPLE'; else simpleBgColor" class="multiple_color flex_center_align_center">
            <img src="assets/images/icons/ic_circle_rgb.svg" class="w-100 h-100">
        </div>
        <ng-template #simpleBgColor>
            <div [ngStyle]="{background: row['rgb']}" class="color_section"></div>
        </ng-template>
    </div>

    <label class="checkbox ml-10" *ngSwitchCase="'CHECKBOX'">
        <input type="checkbox" #checkbox
               [checked]="row.checked"
               (click)="$event.stopPropagation();"
               (change)="row.checked = checkbox.checked; emitNewEvent('CHECKBOX',{ value: row.checked})"
        >
        <span class="checkmark" [ngClass]="{checked:checkbox.checked}">
            <img src="assets/images/table/check.svg">
        </span>
    </label>


    <span *ngSwitchCase="'DATE'" class="showOnHoverParent">
        {{((settings?.key || '') | getValueByKeyFromObject : row
        : [] : '') | toLocaleDate : (realSettings.format || '')}}
    </span>

    <span *ngSwitchCase="'DATE_WITH_STATUS_ORDER'" class="showOnHoverParent">
        <div *ngIf="(realSettings?.data?.status &&
            ['DETERMINED_DATE','AS_SOON_AS_POSSIBLE'].includes((realSettings?.data?.status || '') | getValueByKeyFromObject : row));
            else withStatus"
        >
             <span [ngClass]="{red:(('status' | getValueByKeyFromObject : row) !== 'CANCELED')
                   && (((settings?.key || '') | getValueByKeyFromObject : row) | checkPassedDatePipe: row.diapason : row.deliveredOn) }"
             >
                 {{((settings?.key || '') | getValueByKeyFromObject : row
                    : [] : '') | toLocaleDate : (realSettings.format || '')
                    : realSettings?.data?.changeTimeZoneSuccess
                 }}
             </span>
        </div>
        <ng-template #withStatus>
             {{'order.checkWithTheUser' | translate}}
        </ng-template>
    </span>


   <span *ngSwitchCase="'DATE_PASSED'"
         [ngClass]="{red:(row.deliverOn | checkPassedDatePipe: row.diapason :  ((settings?.key || '') | getValueByKeyFromObject : row)) }"
   >
         {{((settings?.key || '') | getValueByKeyFromObject : row
       : [] : '') | toLocaleDate : (realSettings.format || '')
       : realSettings?.data?.changeTimeZoneSuccess
       }}
   </span>

    <div *ngSwitchCase="'STATUS_WITH_ENUM'">
        {{realSettings.data.enum[((settings?.key || '') | getValueByKeyFromObject : row)] || '---'}}
    </div>

    <div class="multiple_checkbox flex_align_center" *ngSwitchCase="'STATUS_WITH_CHECKBOX'">
        <mat-slide-toggle
                [checked]="row.status === 'ACTIVE'"
                [disabled] = "realSettings?.data?.disableValue"
                (click)="$event.stopPropagation();"
                (change)="row.status = $event.checked ? 'ACTIVE' : 'INACTIVE'; emitNewEvent('STATUS_WITH_CHECKBOX',{ value: row})"
        ></mat-slide-toggle>
    </div>

    <div class="status_selection_or_show flex_align_center"
         *ngSwitchCase="'APPROVE_DECLINE'"
         [class]="realSettings.class"
    >
        <ng-container *ngIf="realSettings.data[((settings?.key || '') | getValueByKeyFromObject : row)] as value">
            <div class="name" [style.color]="value.color">
                {{value.text}}
            </div>

            <mat-select *ngIf="value?.selectableValues as selectItems">
                <mat-option *ngFor="let elem of selectItems"
                            (click)="emitNewEvent('APPROVE_DECLINE', {value: elem.value, row: row})"
                >
                    {{elem.text | translate}}
                </mat-option>
            </mat-select>
        </ng-container>
    </div>

    <div class="multiple_checkbox flex_align_center" *ngSwitchCase="'AVAILABLE_WITH_CHECKBOX'">
        <mat-slide-toggle
                [checked]="row.isAvailable"
                [disabled]="realSettings?.data?.disableValue"
                (click)="$event.stopPropagation();"
                (change)="row.isAvailable = $event.checked; emitNewEvent('AVAILABLE_WITH_CHECKBOX',{ value: row})"
        ></mat-slide-toggle>
    </div>

    <div *ngSwitchCase="'DROPDOWN'"
         class="dropdown flex_center_align_center"
         (click)="emitNewEvent('DROPDOWN', {table: subTable, row: row})"
    >
        <img src="assets/images/icon/{{(subTable.hidden) ? 'ic_down_black.svg' : 'ic_top_black.svg'}}"
             class="pointer mb-10"
        >
    </div>

    <div *ngSwitchCase="'MORE_DROPDOWN'"
         class="dropdown flex_center_align_center"
         [matMenuTriggerFor]="menu"
    >
        <img src="assets/images/table/more-vector.svg"
             class="pointer mb-10"
        >
        <mat-menu #menu="matMenu">
            <button *ngIf="realSettings?.data?.edit" mat-menu-item class="flex_align_center"
                    (click)="emitNewEvent('EDIT_SINGLE')">
                <img src="assets/images/table/ic_edit.svg">
                <span class="ml-10">{{'table.tool_title.edit' | translate}}</span>
            </button>
            <button *ngIf="realSettings?.data?.trash" mat-menu-item class="flex_align_center"
                    (click)="emitNewEvent('TRASH_SINGLE',{id: row.id})">
                <img src="assets/images/table/ic_trash.svg">
                <span class="ml-10">{{'table.tool_title.remove' | translate}}</span>
            </button>
        </mat-menu>
    </div>


    <div *ngSwitchCase="'ARCHIVE'"
         class="flex_center_align_center achieve_container"
    >
        <img *ngIf="!((realSettings.byKey || '') | getValueByKeyFromObject : row);else unarchived"
             src="assets/images/table/archive.svg"
             class="flex_center_align_center"
             (click)="emitNewEvent('ARCHIVE',{id: row.id})" >

        <ng-template #unarchived>
            <img src="assets/images/table/unarchive.svg"
                 class="flex_center_align_center"
                 (click)="emitNewEvent('UNARCHIVE',{id: row.id})" >
        </ng-template>
    </div>

    <div *ngSwitchCase="'MORE_FIELDS'"
         class="flex_align_center achieve_container"
    >
        {{((settings?.key || '') | getValueByKeyFromObject : row)}} ({{((realSettings.byKey || '') | getValueByKeyFromObject : row)}})
    </div>

    <div *ngSwitchCase="'IMAGE'"
         (mouseenter)="mouseEnter($event)"
         class="default_image showOnHoverParent"
    >

        <img [src]="((row | stringOrArrayToString : settings?.key) ? (row | stringOrArrayToString : settings?.key ) : 'assets/images/table/rose.jpg')"
             class="pointer mb-10"
        >

        <div class="showOnHover showOnHoverImage" #imagePopup>
            <img [src]="((row | stringOrArrayToString : settings?.key) ? (row | stringOrArrayToString : settings?.key ) : 'assets/images/table/rose.jpg')"
                 class="pointer mb-10"
            >
        </div>
    </div>

    <div *ngSwitchCase="'PHONE'">
        {{(settings?.key || '') | getValueByKeyFromObject : row | phoneNumber}}
    </div>

    <div *ngSwitchCase="'RATE'">
        {{(settings?.key || '') | getValueByKeyFromObject : row | number : '0.1-1'}}
    </div>

    <div *ngSwitchCase="'AVERAGE_RATE'">
        <span *ngIf="+((realSettings.byKey || '') | getValueByKeyFromObject : row) > 3;else lessThanEqualThree">
                  {{+((settings?.key || '') | getValueByKeyFromObject : row) | number : '0.2-2' | replaceToComma}}
              </span>
        <ng-template #lessThanEqualThree>
                    <span *ngIf="+((realSettings.byKey || '') | getValueByKeyFromObject : row) === 0">
                      {{ 'star_rating.noRates' | translate }}
                    </span>
            <span *ngIf="+((realSettings.byKey || '') | getValueByKeyFromObject : row) > 0 && +((realSettings.byKey || '') | getValueByKeyFromObject : row) <= 3">
                      {{ 'star_rating.lessRates' | translate }}
                    </span>
        </ng-template>

        <span *ngIf="realSettings.byKey">
         ({{+((realSettings.byKey || '') | getValueByKeyFromObject : row)}})</span>
    </div>

    <div *ngSwitchCase="'DELIVERY_TIME'">
        {{(settings?.key || '') | getValueByKeyFromObject : row | deliveryTime}}
    </div>

    <div *ngSwitchCase="'NUMBER'">
        {{(settings?.key || '') | getValueByKeyFromObject : row}}
    </div>

    <div *ngSwitchCase="'AMOUNT'">
        <ng-container
                *ngIf="(settings && settings.header && settings.header[(settings?.key || '') | arrayToString]?.data?.type) as type">
            <ng-container [ngSwitch]="type">
                <div *ngSwitchCase="'PRICE'">
                    <ng-container
                            *ngIf="((settings?.key || '') | getValueByKeyFromObject : row | amountTypePipe) as value; else Empty">
                        <span [ngStyle]="{color:(settings && settings.header && settings.header[(settings?.key || '') | arrayToString]?.data?.color)}">{{value}}
                            ₽</span>
                    </ng-container>
                    <ng-template #Empty>
                        ---
                    </ng-template>
                </div>
                <div *ngSwitchCase="'amountType'">
                    {{(settings?.key || '') | getValueByKeyFromObject : row | amountTypePipe}}
                    <ng-container *ngIf="row.amountType as AmountType">
                        {{AmountType === 'PERCENT' ? '%' :
                         (AmountType === 'PRICE' ? '₽' :
                         (AmountType === 'FREE_DELIVERY' ? 'Бесплатное доставка' : '-')
                        )}}
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>

    </div>

</ng-container>
