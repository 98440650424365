export const endpointsConstant = {
    // AUTH MODULE ROUTES
    auth: 'auth',
    count: 'count',
    sendCode: 'send-code',
    checkCode: 'check-code',
    seller: 'seller',
    register: 'register',
    login: 'login',
    products: 'products',
    shop: "shop",
    shops: "shops",
    image: 'image',
    users: 'users',
    refreshToken: 'auth/renew',
    cities: 'cities',
    city: 'city',
    categories: 'categories',
    types: 'types',
    composition: 'flowers',
    colors: 'colors',
    assign: 'assign',
    orders: 'orders',
    uploadVideo: 'upload-video',
    review: 'review',
    skip: 'skip',
    order: 'order',
    all: 'all',
    finance: 'finance',
    status: 'status',
    generate: 'generate',
    manage: 'manage',
    address: 'address',
    deliveryOn: 'delivery-on',
    firebase: 'firebase',
    sendNotification: 'send-notification',
    badges: 'badges',
    promoCode: 'promocode',
    employee: 'employee',
    employees: 'employees',
    permissions: 'permissions',
    filtered: 'filtered',
    filter: 'filter',
    approvalStatus: 'approval-status',
    bounds: 'bounds',
    export: 'export',
    resetPassword: 'reset-password',
    checkExists: 'check-exists',
    decline: 'decline',
    archived: 'archived',
    exportPromoCodeUsage: 'export-promocode-usage',
    section: 'section',
    chat: 'chat',
    active: 'active',
    archive: 'archive',
    messages: 'messages',
    send: 'send',
    firebaseToken: 'firebase',
    chats: 'chats',
    adminChats: 'admin-chats'
};
