import {Pipe, PipeTransform} from '@angular/core';
//services
import {ParseDateService} from "@services/date/parse-date.service";

@Pipe({
    name: 'checkPassedDatePipe'
})
export class CheckPassedDatePipe implements PipeTransform {

    constructor(private parseDateService: ParseDateService
    ) {}

    transform(
        date: string,
        diapason: number,
        secondDate?: string
    ): boolean {
        const deliveryMaxDate = this.parseDateService.getDiapasonRange(date, diapason);
        const secondDateString  = secondDate ? this.parseDateService.getDiapasonRange(secondDate, diapason) : '';
        return this.parseDateService.checkLateDate(deliveryMaxDate, secondDateString);
    }
}
