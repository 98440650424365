import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
// Constants
// Services
import {RequestMethodsService} from "@services/request/request-methods.service";
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
import {endpointsConstant} from "@intrerfaces/global/endpoints.constant";
import {v4} from "uuid";
import {AuthService} from "@services/auth/auth.service";
import {RoleEnum} from "@intrerfaces/user/modules/role.enum";
import {RequestMethodRoleEnum} from "@intrerfaces/user/request-methods/request-method-role.enum";


@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    firebaseTokeId = 'FirebaseTokeId'

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private requestService: RequestMethodsService,
        private authService: AuthService
    ) {}

    requestPermission() {
        this.angularFireMessaging.requestPermission.subscribe((permission) => {
          console.log(permission)
            if (permission === 'denied') { return; }
            this.sendToken()
        })
    }

    sendToken() {
        this.angularFireMessaging.requestToken.subscribe((token) => {
            if (!token) { return; }
            const data = localStorage.getItem(this.firebaseTokeId);
            let deviceId;
            if (data) {
                deviceId = JSON.parse(data)?.deviceId;
            } else {
                deviceId = v4();
                localStorage.setItem(this.firebaseTokeId, JSON.stringify({deviceId}))
            }
            const role = this.authService.getTokenAndRole() === RoleEnum.ROLE_ADMIN ? RequestMethodRoleEnum.admin : RequestMethodRoleEnum.seller;
            this.requestService.post(endpointsConstant.firebaseToken + '/' + role + '/tokens' , {
                fcmToken: token,
                deviceId
            },RequestMethodRoleEnum.global).subscribe((res) => {
                this.receiveMessage();
            })
        });
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe((msg: any) => {
          console.log(msg)
            this.currentMessage.next(msg);
        })
    }

    deleteToken(): void {
        const data = localStorage.getItem(this.firebaseTokeId);
        if (!data) { return;}
        this.angularFireMessaging.deleteToken(JSON.parse(JSON.stringify(data)).deviceId).subscribe()
    }

    run(): BehaviorSubject<any> {
        this.requestPermission()
        return this.currentMessage;
    }

}
