import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeDateSeparatorFormatPipe',
})

export class ChangeDateSeparatorFormatPipe implements PipeTransform {

  private monthsMap: string[] = [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
  ];

  private daysMap: string[] = [
    'Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'
  ];

  transform(value: string | Date): string {
    if (!value) return '';

    let date!: Date;

    // If value is already a Date object
    if (value instanceof Date) {
      date = value;
    } else {
      // Convert from MM/DD/YYYY format
      const parts = value.split('/');
      if (parts.length === 3) {
        const month = parseInt(parts[0], 10) - 1;
        const day = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);
        date = new Date(year, month, day);
      }

      if (!date || isNaN(date.getTime())) return '';
    }

    const today = new Date();
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    if (isToday) {
      return 'Сегодня'; // If today, return "Сегодня"
    }

    const dayOfWeek = this.daysMap[date.getDay()];
    const day = date.getDate();
    const month = this.monthsMap[date.getMonth()];

    return `${dayOfWeek}, ${day} ${month}`;
  }
}
