import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {Observable} from "rxjs";
// Services
import {RequestMethodsService} from "../request/request-methods.service";

// Constants
import {CheckToken, TokenInterface} from "@intrerfaces/auth/login.interface";
import {endpointsConstant} from "@intrerfaces/global/endpoints.constant";
import {RequestMethodRoleEnum} from "@intrerfaces/user/request-methods/request-method-role.enum";
import {RoleEnum} from "@intrerfaces/user/modules/role.enum";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  tokenNameInLocalStorage = 'AuthTokenRetail';

  constructor(
      private router: Router,
      private requestService: RequestMethodsService,
  ) { }

  /**
   * Save Authorization token in local Storage and redirect to Admin panel
   * @param token (Authorization token)
   */
  saveTokenInLocalStorage(token: TokenInterface, navigateAccess = true, auth = false,role = RequestMethodRoleEnum.admin): void {
      localStorage.setItem(this.tokenNameInLocalStorage, JSON.stringify(token));
      if (navigateAccess) {
          if (role === RequestMethodRoleEnum.admin) {
              this.router.navigate([(auth) ? '/' : '/login']);
          } else if(role === RequestMethodRoleEnum.seller){
              this.router.navigate([(auth) ? '/seller' : '/seller/login']);
          }
      }
  }

  /**
   * Get Authorization Token from local storage and return parsed token if exists
   */
  getToken(): TokenInterface | null {
      try {
          const token = localStorage.getItem(this.tokenNameInLocalStorage);
          if (token) {
              const parsedToken = JSON.parse(token);
              return (parsedToken?.accessToken) ? parsedToken : null;
          }
          return null;
      } catch (e) {
          return null;
      }
  }

  /**


  /**
   * get user information object from token which saved in Local Storage
   */
  // getUserInfo(): FullUser | null {
  //     const token = this.getToken();
  //     if (token) { return token.user; }
  //     return null;
  // }

  /**
   * get token and check expire time and return Boolean (true if not expired)
   */

  checkToken(): CheckToken {
      const token: TokenInterface | null = this.getToken();
      if (token) {
          return CheckToken.VALID;
      }
      return CheckToken.INVALID;

  }


    getTokenAndRole(): RoleEnum {
        const token: TokenInterface | null = this.getToken();
        if (token) {
            return token.roles[0] == RoleEnum.ROLE_ADMIN ? RoleEnum.ROLE_ADMIN :  RoleEnum.ROLE_SELLER;
        }
        return RoleEnum.INVALID_USER
    }

    getBadgesMessages(): Observable<any> {
        return  this.requestService.get(`${endpointsConstant.badges}/${endpointsConstant.chats}`,RequestMethodRoleEnum.global);
    }


  /**
   * Remove token from Local storaqge and navigate to Auth page
   */
  logout(): void {
      const role = this.getTokenAndRole();
      localStorage.clear();
      this.router.navigate([role === RoleEnum.ROLE_ADMIN ? '/admin/login' : '/seller/login']);
  }

  /**
   *  Make request to get new token with refresh token, and getting response new Token object
   */
  tryToRefreshToken(role :RequestMethodRoleEnum = RequestMethodRoleEnum.admin): Observable<any> {
      return this.requestService.post(endpointsConstant.refreshToken, {}, role);
  }
}
