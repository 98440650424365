import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Pipes
import { ShowErrorPipe } from './reactive-form-validation/show-error.pipe';
import {InArrayPipe} from './array-methods/in-array';
import { MakeArrayFromNumberPipe } from './array-methods/make-array-from-number.pipe';
import { ToLocaleDatePipe } from './date-methods/to-locale-date.pipe';
import {InStringPipe} from "./string-methods/in-string.pipe";
import { ArrayToStringPipe } from './array-methods/array-to-string.pipe';
import {GetDataByKeyPipe} from "./array-methods/get-data-by-key.pipe";
import {PhoneNumberPipe} from "./string-methods/phone-number.pipe";
import {ShowByFormatPipe} from "./date-methods/show-by-format.pipe";
import {GetValueByKeyFromObjectPipe} from "./string-methods/get-value-by-key-from-object.pipe";
import {
    CloneTableDataConfigsObjectAndSetNewDadaPipe
} from "./object-methods/clone-table-data-configs-object-and-set-new-dada.pipe";
import {AddFieldToObjectPipe} from "./object-methods/add-field-to-object.pipe";
import {GetArrayByKeyFromObjectPipe} from "./array-methods/get-array-by-key-from-object.pipe";
import {VariableTypeCheckPipe} from "./variable-type/variable-type-check.pipe";
import {StringOrArrayToStringPipe} from "./array-methods/string-or-array-to-string.pipe";
import {FilterArrayByIdPipe} from "./array-methods/filter-array-by-id.pipe";
import {MakePaginationCountArrayPipe} from "./array-methods/make-pagination-count-array.pipe";
import {HasKeysPipe} from "./object-methods/check-keys.pipe";
import {TypeForAmountPipe} from "./string-methods/type-for-amount.pipe";
import {RuTimePipe} from "./date-methods/ru-time.pipe";
import {DeliveryTimePipe} from "./string-methods/delivery-time.pipe";
import {CheckPassedDatePipe} from "./date-methods/check-passed-date.pipe";
import {SecondsToHoursPipe} from "./date-methods/seconds-to-hours.pipe";
import {ReplaceToCommaPipe} from "./string-methods/replace-to-comma.pipe";
import {SearchInArrayPipe} from "./array-methods/search-in-array.pipe";
import {FirstLetterPipe} from "./first-latter/first-letter.pipe";
import {ChangeDateSeparatorFormatPipe} from "./chat/change-date-separator-format.pipe";

const pipes = [
   ShowErrorPipe, InArrayPipe,
   MakeArrayFromNumberPipe, ToLocaleDatePipe,
   InStringPipe,TypeForAmountPipe,
   ArrayToStringPipe,
   GetDataByKeyPipe,
   PhoneNumberPipe,
   RuTimePipe,
   ShowByFormatPipe, GetValueByKeyFromObjectPipe, CloneTableDataConfigsObjectAndSetNewDadaPipe,
   AddFieldToObjectPipe, GetArrayByKeyFromObjectPipe, VariableTypeCheckPipe,
   StringOrArrayToStringPipe, FilterArrayByIdPipe,MakePaginationCountArrayPipe,HasKeysPipe, DeliveryTimePipe,
   CheckPassedDatePipe,SecondsToHoursPipe,ReplaceToCommaPipe, SearchInArrayPipe,FirstLetterPipe,ChangeDateSeparatorFormatPipe
];

@NgModule({
  declarations: pipes,
  imports: [
    CommonModule
  ],
  exports: pipes
})
export class PipesModule { }

