export const environment = {
  production: true,
  yandexMapKey: 'd30beba5-a507-4e6b-9d7b-48c0354d06c0',
  publicLink: 'https://dev-api.click-market.ru',
  dataEndpoint: 'https://dev-api.click-market.ru/api/v1',
  socketUrl: 'dev-api.click-market.ru',
  // socketUrl: '192.168.1.19:8080',
  // dataEndpoint: 'http://192.168.1.19:8080/api/v1',
  recaptcha: {
    siteKey: '6Lfc_YcpAAAAAAu_pQXMylit7RDBG3h5oSwDGH4A',
  },
  firebaseConfig: {
    apiKey: "AIzaSyCpcWgKyM3GZg6BPEdWHUfUVhLFmwIX_v0",
    authDomain: "click-market-376010.firebaseapp.com",
    projectId: "click-market-376010",
    storageBucket: "click-market-376010.firebasestorage.app",
    messagingSenderId: "1059328573535",
    appId: "1:1059328573535:web:28063f353ad7f9dba91bea",
    measurementId: "G-2SNZDTQCQ8"
  }
};
