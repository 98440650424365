import { Component } from '@angular/core';
import {AuthService} from "@services/auth/auth.service";
import {MessagingService} from "@services/firebase/messaging.service";
import {GlobalSubscriptionService} from "@services/subscribtions/global-subscription.service";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentMessage!: BehaviorSubject<any>;
  dataNotification: any
  constructor(
      private authService: AuthService,
      private messagingService: MessagingService,
      private globalSubscriptionService: GlobalSubscriptionService,
  ) {
    if (this.authService.getToken()) {
      this.start()
    }
  }


  start(): void {
    this.currentMessage = this.messagingService.run();
    this.currentMessage.subscribe((res) => {
      this.dataNotification = res;
      this.globalSubscriptionService.sendMessage('badges',res)
      setTimeout(() => {
        this.dataNotification = null;
      },7700)
    })
  }
}
