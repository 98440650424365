import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetter'
})
export class FirstLetterPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return ''; // Handle null or undefined
    const trimmedValue = value.trim();
    return trimmedValue ? trimmedValue.charAt(0) : 'A';
  }
}
