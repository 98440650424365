import {Injectable} from '@angular/core';
import {ShopInterface} from "@intrerfaces/user/modules/shop.interface";

@Injectable({
    providedIn: 'root'
})
export class ShopService {
    shopModel = 'shopModel';

    setShopModel(shopModel: ShopInterface): void {
        localStorage.setItem(this.shopModel, JSON.stringify(shopModel))
    }

    getShopIdFromLocalStorage(): number | null {
       try {
           const json = localStorage.getItem(this.shopModel);
           if (!json) { return null; }
           const shopModel = JSON.parse(json);
           return shopModel?.id ? +shopModel.id : null;
       } catch (e: any) {
           return null
       }
    }

    getShopInfo(): any {
        try {
            const json = localStorage.getItem(this.shopModel);
            if (!json) { return null; }
            const shopModel = JSON.parse(json);
            return shopModel ? shopModel : null;
        } catch (e: any) {
            return null
        }
    }


}
