import {AfterViewInit, Component, Input} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {ParseDateService} from "@services/date/parse-date.service";

@Component({
  selector: 'app-filtration-base',
  template: '',
  styles: []
})
export class FiltrationBaseComponent implements AfterViewInit{
  @Input() filtrationBody!: {[key: string]: number | string};
  @Input() data!: {contractId?: number};
  @Input() optionalData!: {contractId?: number};
  form: UntypedFormGroup = new UntypedFormGroup({});
  ready!: boolean;
  dateService!: ParseDateService;

  constructor() {
    setTimeout(() => this.ready = true, 1)
    this.dateService = new ParseDateService();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.filtrationBody) {
        this.form.patchValue({
          ...this.form.value,
          ...this.filtrationBody
        })
        for (let key in this.filtrationBody) {
          if (key.includes('Date') && this.filtrationBody[key]) {
            this.form.controls[key]?.setValue( this.dateService.changeFormat(this.filtrationBody[key]?.toString(),'M/D/yyyy'))
          }
        }
      }
    },1)
  }

  _setValue(key: string, value: any): void {
    this.form.controls[key].setValue(value);
  }

  setControls(controls: Array<string>): void {
    controls.forEach((control: string) => this.form.addControl(control, new UntypedFormControl()))
  }

  setFormDataToPaginationBody(): void {
    const controls = this.form.controls;
    Object.keys(controls).forEach((control: string) => {
      let value = controls[control].value;
      if (control.includes('DateTime') && value) {
        value = this.dateService.changeFormat(controls[control].value, 'YYYY-MM-DDTHH:mm:ss')
      } else if ((control.includes('Date')) && value) {
        value = this.dateService.changeFormat(controls[control].value, 'YYYY-MM-DD') + (control.includes('end') || control.includes('max') ? 'T23:59:59' : 'T00:00:00')
      }
      if(value?.length || (typeof value === 'number')){
        this.filtrationBody[control] = value
      }
      // this.filtrationBody[control] = value?.length ? value : null;
    })

  }

  replaceElementFromFormControl(control: any,index: number, count: number): void {
    if (control && (index || index === 0) && count) {
      control.value.splice(index,count)
    }
  }

  resetFormValue(key: string): void {
    this.form.controls[key].reset();
  }

  resetForm(): void {
    this.form.reset();
  }

}
